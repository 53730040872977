import memoize from "fast-ts-helpers/memoize"
import tuple from "fast-ts-helpers/tuple"
import wuzzy from "wuzzy"

/**
 * Searches for options that match a given query string, using n-gram similarity. If the query string is shorter than the n-gram size, it will search for the query string as a substring of the options.
 *
 * @param query - The query string to search for.
 * @param options - An array of tuples, where the first element is the option to search and the second element is the string to compare against.
 * @param n - The size of the n-grams to use for similarity comparison. Defaults to 3.
 * @returns An array of options that match the query string, sorted by similarity.
 */
const fuzzySearch = memoize(
	<T extends any>(
		query: string | null | undefined,
		options: [T, string][] | null | undefined
	): T[] => {
		if (!options) {
			return []
		}

		if (!query) {
			return options.map((o) => o[0])
		}

		return options
			.map((option) => {
				const a = option[1].toLocaleLowerCase()
				const b = query.toLocaleLowerCase()

				// If the query is an exact match, give it a major handicap
				const handicap = a.includes(b) || b.includes(a) ? 1 : 0

				// For short queries or ID queries, prefer Jaro-Winkler similarity, which
				//   gives preference to prefix matches.
				if (query.length < 3 || /^[0-9]+(-[0-9a-z.]+)*$/gi.test(b)) {
					return tuple(option[0], wuzzy.jarowinkler(a, b, 10) + handicap)
				}

				// For other queries, use n-gram similarity.
				return tuple(option[0], wuzzy.ngram(a, b, 3) + handicap)
			})
			.filter(([, similarity]) => similarity > 0)
			.sort(([, a], [, b]) => b - a)
			.map((o) => o[0])
	}
)

export default fuzzySearch
