import React, { useEffect, useMemo, useState } from "react"

import tuple from "fast-ts-helpers/tuple"
import { useNavigate, useParams } from "react-router-dom"
import { twMerge } from "tailwind-merge"
import { ClassNameValue } from "tailwind-merge/dist/lib/tw-join"
import { useQuery } from "urql"

import { graphql } from "../../graphql"
import { UsersQuery } from "../../graphql/graphql"
import fuzzySearch from "../../utils/fuzzySearch"

import Button from "../Button"

export type UserSidebarUser = UsersQuery["users"][number]

export const usersQuery = graphql(`
	query users {
		users {
			id
			name
			email
			isAdmin
			pendingAgencies {
				id
			}
		}
	}
`)

export type UserSidebarProps = {
	className?: ClassNameValue
}

const UserSidebar: React.FC<UserSidebarProps> = ({ className }) => {
	const navigate = useNavigate()
	const { user: selectedUserId } = useParams() as { user?: string }

	// This is a high level component (no page parent, it's a child of content wrapper).
	//   There's not really a better place to put this query.
	const [{ data }] = useQuery({ query: usersQuery })

	const [query, setQuery] = useState("")

	useEffect(() => {
		if (!selectedUserId && data?.users[0]) {
			navigate(`/admin/user-manager/${data.users[0].id}`, { replace: true })
		}
	}, [data?.users, navigate, selectedUserId])

	const filtered = useMemo(
		() =>
			fuzzySearch(
				query,
				data?.users.map((u) =>
					tuple(
						u,
						`${u.name} ${u.email}${
							u.pendingAgencies.length && !u.isAdmin ? " action required" : ""
						}`
					)
				)
			),
		[data?.users, query]
	)

	return (
		<aside className={twMerge("border-r border-solid border-gray-2 bg-white", className)}>
			<div className="flex h-[calc(100vh-6rem)] flex-col">
				<h3 className="m-2 font-bold">Users</h3>
				<hr />
				<input
					type="search"
					placeholder="Search users…"
					className="m-1 rounded bg-gray-1 p-2.5"
					value={query}
					onChange={(e) => setQuery(e.target.value)}
				/>
				<div
					className="flex flex-1 flex-col overflow-y-auto overflow-x-hidden"
					role="listbox"
				>
					{filtered.map((user) => {
						const { id, name, email, isAdmin, pendingAgencies } = user

						const rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu")

						const allInitials = [...name.matchAll(rgx)] || []
						const initials = (
							(allInitials.shift()?.[1] || "") + (allInitials.pop()?.[1] || "")
						).toUpperCase()

						return (
							<Button
								plain
								key={email}
								to={`/admin/user-manager/${id}`}
								role="option"
								className="grid w-full grid-cols-[auto_1fr] items-center gap-x-1.5 border-t border-gray-3 px-2.5 py-3 text-left first:border-t-0 aria-selected:bg-gray-1"
								aria-selected={selectedUserId === id}
							>
								<div className="row-span-2 flex size-9 flex-none items-center justify-center rounded-[3px] bg-red/7 text-sm font-bold">
									{initials}
								</div>
								<div className="flex justify-between">
									<span>{name}</span>
									{pendingAgencies.length > 0 && !isAdmin && (
										<span className="rounded-[3px] bg-warning-100 px-1 pt-px text-xs text-warning">
											action required
										</span>
									)}
								</div>
								<span className="-mt-1.5 truncate text-xs text-gray-5">{email}</span>
							</Button>
						)
					})}
				</div>
			</div>
		</aside>
	)
}

export default UserSidebar
