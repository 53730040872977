import React from "react"

import { twMerge } from "tailwind-merge"
import { ClassNameValue } from "tailwind-merge/dist/lib/tw-join"

import Paper from "../Paper"

import logo from "./lsa-seal.webp"

export type PaperPageTemplateProps = {
	className?: ClassNameValue
	hideLogo?: boolean
	hideHeader?: boolean
	children?: React.ReactNode
}

const PaperPageTemplate: React.FC<PaperPageTemplateProps> = ({
	className,
	hideLogo,
	hideHeader,
	children
}) => {
	return (
		<main className="flex min-h-screen flex-col items-center justify-center pb-[12vh]">
			{!hideHeader && (
				<h1 className="text-center text-lg font-bold uppercase">
					Alabama Administrative Code Management System
				</h1>
			)}
			<Paper
				className={twMerge(
					"mt-4 flex max-w-2xl flex-col items-center",
					hideLogo ? "pb-20 pt-[4.3125rem]" : "pb-[3.1875rem] pt-[3.8125rem]",
					className
				)}
			>
				{!hideLogo && (
					<img className="mb-4 aspect-[105/142.45] w-[6.5625rem]" src={logo} alt="logo" />
				)}
				{children}
			</Paper>
		</main>
	)
}

export default PaperPageTemplate
